import { render, staticRenderFns } from "./wallet-list.vue?vue&type=template&id=2564077e&scoped=true&"
import script from "./wallet-list.vue?vue&type=script&lang=ts&"
export * from "./wallet-list.vue?vue&type=script&lang=ts&"
import style0 from "./wallet-list.vue?vue&type=style&index=0&id=2564077e&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2564077e",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/sandeshbhoir/Documents/vue/biggest-supplier-admin/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('2564077e')) {
      api.createRecord('2564077e', component.options)
    } else {
      api.reload('2564077e', component.options)
    }
    module.hot.accept("./wallet-list.vue?vue&type=template&id=2564077e&scoped=true&", function () {
      api.rerender('2564077e', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/users/wallet-list.vue"
export default component.exports